import type { RouteType } from "ui-kit";
import { NotFoundPage, React, Redirect } from "ui-kit";

import { bookingsRoutes } from "./bookings";
import { desksRoutes } from "./desk";
import { eventsRoutes } from "./events";
import { menuRoutes } from "./menu";
import { organizationRoutes } from "./organization";
import { roomsRoutes } from "./room";
import { userRoutes } from "./user";
import { visitorRoutes } from "./visitor";

const getApplicationRoutes = (routesConfig: RouteType[]): RouteType[] => {
  const definedRoutes = routesConfig.map((route) => ({
    ...route,
    exact: route?.exact || true,
  }));

  return [
    {
      path: "/",
      exact: true,
      component: () => <Redirect to="/organization" />,
    },
    ...definedRoutes,
    {
      path: "*",
      component: () => <NotFoundPage />,
    },
  ];
};

const applicationRoutes: RouteType[] = [
  ...menuRoutes,
  ...organizationRoutes,
  ...desksRoutes,
  ...roomsRoutes,
  ...userRoutes,
  ...bookingsRoutes,
  ...eventsRoutes,
  ...visitorRoutes,
];

export interface AssetDetailRouteParams {
  assetId: string;
}
export interface BookingDetailRouteParams {
  id: string;
}

export interface EventDetailRouteParams {
  eventId: string;
}

export default getApplicationRoutes(applicationRoutes);
