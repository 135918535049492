import { initialState } from "./constants";
import type { ActionHandlers, IAction, IListFiltersState } from "./types";

// Define how the state should update for each action type.
const actionHandlers: ActionHandlers = {
  updateAssetsFilter: (state, action) => ({
    ...state,
    [action.payload.assetType]: {
      ...state[action.payload.assetType],
      [action.payload.filterName as string]: action.payload.filterValue,
    },
  }),
  resetAssetFilters: (state, action) => ({
    ...state,
    [action.payload.assetType]: initialState[action.payload.assetType],
  }),
};

// Main reducer function. Applies the action handler for a given action type.
export default function listFiltersReducer(
  state: IListFiltersState,
  action: IAction
) {
  const actionHandler = actionHandlers[action.type];
  if (actionHandler) {
    return actionHandler(state, action);
  }
  return state;
}
