import type { RouteType } from "ui-kit";
import { lazy } from "ui-kit";

export const bookingsRoutes: RouteType[] = [
  {
    path: "/:groupId/bookings/my",
    component: lazy(() => import("pages/bookings/My")),
  },
  {
    path: "/:groupId/bookings/:id",
    component: lazy(() => import("pages/bookings/Detail")),
  },
  {
    path: "/:groupId/bookings/:id/success",
    component: lazy(() => import("pages/bookings/Success")),
  },
];
