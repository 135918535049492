import type { RouteType } from "ui-kit";
import { lazy } from "ui-kit";

export const visitorRoutes: RouteType[] = [
  {
    path: "/:groupId/visitor/create",
    component: lazy(() => import("pages/visitor/create")),
  },
  {
    path: "/:groupId/visitor/create/success",
    component: lazy(() => import("pages/visitor/success")),
  },
];
