import type { IListFiltersState } from "./types";

export const initialState: IListFiltersState = {
  desk: {
    building: null,
    floor: null,
    area: null,
    zone: null,
    amenities: null,
    capacity: 0,
    isFreeAsset: false,
  },
  room: {
    building: null,
    floor: null,
    area: null,
    zone: null,
    amenities: null,
    capacity: 0,
    isFreeAsset: false,
  },
};
