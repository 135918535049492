import "./index.css";

import { Auth0ProviderWithHistory, bootstrapApp, ErrorScreen } from "ui-kit";

import App from "./App";
import config from "./config";
import en from "./i18n/en.json";
import newKeys from "./i18n/new-keys.json";

const { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN, DEBUG } = config;

bootstrapApp({
  App,
  Auth0Provider: Auth0ProviderWithHistory,
  Auth0ProviderOptions: {
    clientId: AUTH0_CLIENT_ID,
    domain: AUTH0_DOMAIN,
    audience: AUTH0_AUDIENCE,
    redirectUri: window.location.origin,
  },
  ErrorScreen,
  messages: { en, newKeys },
  querySelector: "#root",
  DEBUG,
});
