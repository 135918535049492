import { AssetTypes } from "common";
import type { Dispatch, ReactNode } from "ui-kit";
import { createContext, React, useContext, useReducer } from "ui-kit";

import { resetAssetFiltersAction, updateAssetFilterAction } from "./actions";
import { initialState } from "./constants";
import reducer from "./reducer";
import type { IAction, IListFiltersState } from "./types";

interface Props {
  children: ReactNode;
}

const ListFiltersStateContext = createContext<IListFiltersState>(initialState);
const ListFiltersDispatchContext = createContext<Dispatch<IAction> | null>(
  null
);

export const ListFiltersContext = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ListFiltersStateContext.Provider value={state}>
      <ListFiltersDispatchContext.Provider value={dispatch}>
        {children}
      </ListFiltersDispatchContext.Provider>
    </ListFiltersStateContext.Provider>
  );
};

export const useListFilters = (assetType: AssetTypes) => {
  const [state, dispatch] = [
    useContext(ListFiltersStateContext),
    useContext(ListFiltersDispatchContext),
  ];

  return {
    actions: {
      updateAssetFilter: (filterName: string, filterValue: unknown) =>
        dispatch?.(updateAssetFilterAction(filterName, filterValue, assetType)),
      resetAssetFilters: () => {
        dispatch?.(resetAssetFiltersAction(assetType));
      },
    },
    state,
  };
};
