import type { RouteType } from "ui-kit";
import { lazy } from "ui-kit";

export const desksRoutes: RouteType[] = [
  {
    path: "/:groupId/desk/initial-filters",
    component: lazy(() => import("pages/desk/InitialFilters")),
  },
  {
    path: "/:groupId/desk/list",
    component: lazy(() => import("pages/desk/List")),
  },
  {
    path: "/:groupId/desk/:assetId",
    component: lazy(() => import("pages/desk/Detail")),
  },
];
