import { AssetTypes } from "common";
import { AutocompleteOption } from "ui-kit";

type FilterOption = AutocompleteOption[] | null;
export interface IAssetsFilterState {
  building: FilterOption;
  floor: FilterOption;
  area: FilterOption;
  zone: FilterOption;
  amenities: string[] | null;
  capacity: number;
  isFreeAsset: boolean;
}

interface ISetFilterPayload {
  filterName?: string;
  filterValue?: unknown;
  assetType: AssetTypes;
}

type PayloadTypes = ISetFilterPayload;

// The full filters state to be stored in context
// Expected to become { deskFilters: {...}, roomFilters: {...} } etc.
export interface IListFiltersState {
  desk: IAssetsFilterState;
  room: IAssetsFilterState;
  zone?: IAssetsFilterState;
}

// All action types available to update the filters state.
export enum ActionTypes {
  UPDATE_ASSET_FILTER = "updateAssetsFilter",
  RESET_ASSET_FILTERS = "resetAssetFilters",
}

export interface IAction {
  type: ActionTypes;
  payload: PayloadTypes;
}

export type ActionHandlers = {
  [action in ActionTypes]: (
    state: IListFiltersState,
    action: IAction
  ) => IListFiltersState;
};
