import { AppProvider, FontFace, React, RenderRoutes } from "ui-kit";

import config from "./config";
import { ListFiltersContext } from "./contexts/ListFilters";
import routes from "./routing";

const { DEBUG, GRAPHQL_URL } = config;

export default function App() {
  return (
    <AppProvider uri={GRAPHQL_URL} debug={DEBUG} name="liff">
      <FontFace />
      <ListFiltersContext>
        <RenderRoutes routes={routes} />
      </ListFiltersContext>
    </AppProvider>
  );
}
