import { InstantBookingPath } from "common";
import type { RouteType } from "ui-kit";
import { lazy } from "ui-kit";

export const roomsRoutes: RouteType[] = [
  {
    path: "/:groupId/room/initial-filters",
    component: lazy(() => import("pages/room/InitialFilters")),
  },
  {
    path: "/:groupId/room/filters",
    component: lazy(() => import("pages/room/InitialFilters")),
  },
  {
    path: "/:groupId/room/list",
    component: lazy(() => import("pages/room/List")),
  },
  {
    path: "/:groupId/room/:assetId",
    component: lazy(() => import("pages/room/Detail")),
  },
  {
    path: InstantBookingPath,
    component: lazy(() => import("pages/room/InstantBook")),
  },
];
