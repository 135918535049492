import type { RouteType } from "ui-kit";
import { lazy } from "ui-kit";

export const eventsRoutes: RouteType[] = [
  {
    path: "/:groupId/events/my",
    component: lazy(() => import("pages/events/My/index")),
  },
  {
    path: "/:groupId/events/success",
    component: lazy(() => import("pages/events/Detail/success")),
  },
  {
    path: "/:groupId/events",
    component: lazy(() => import("pages/events/List/index")),
  },
  {
    path: "/:groupId/events/:eventId",
    component: lazy(() => import("pages/events/Detail/index")),
  },
];
