import { AssetTypes } from "common";

import { ActionTypes } from "./types";

export const updateAssetFilterAction = (
  filterName: string,
  filterValue: unknown,
  assetType: AssetTypes
) => ({
  type: ActionTypes.UPDATE_ASSET_FILTER,
  payload: {
    filterName,
    filterValue,
    assetType,
  },
});

export const resetAssetFiltersAction = (assetType: AssetTypes) => ({
  type: ActionTypes.RESET_ASSET_FILTERS,
  payload: {
    assetType,
  },
});
