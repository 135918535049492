import type { RouteType } from "ui-kit";
import { lazy } from "ui-kit";

export const userRoutes: RouteType[] = [
  {
    path: "/:groupId/user-profile",
    component: lazy(() => import("pages/user-profile")),
  },
  {
    path: "/:groupId/user-profile/edit",
    component: lazy(() => import("pages/user-profile/Profile/EditUserForm")),
  },
  {
    path: "/:groupId/user-field/edit",
    component: lazy(
      () => import("pages/user-profile/Profile/EditUserFieldForm")
    ),
  },
  {
    path: "/:groupId/user-profile/access-gate",
    component: lazy(() => import("pages/user-profile/access-gate")),
  },
  {
    path: "/user-profile/personal-credit/:accountId",
    component: lazy(
      () => import("pages/user-profile/credit-details/personal-credit")
    ),
    exact: true,
  },
];
